import React from "react";
import { useTranslation } from "react-i18next";
import { WebUrl } from "../../util/Constant";
import { useSelector, useDispatch } from "react-redux";

const NotFound = () => {
    const { t } = useTranslation();
    const openMenu = useSelector((state) => state.appState.openMenu);

    return (
        <div className="t3-content-container mb-4">
            <div className={openMenu ? "left active" : "left"}></div>
            <div className={openMenu ? "right active" : "right"}>
                <div className="t3-small-container">
                    <div className="not-found-wrapper">
                        <img src={require("../../assets/img/img_404_dark.png")} height="400px" width="800px" alt="404 page not found" />
                        <h3>{t("PAGE_NOT_FOUND")}</h3>
                        <p>{t("PAGE_NOT_FOUND_DESCRIPTION")}</p>
                        <a href={WebUrl._URL_HOME} className="t3-custom-light-btn mt-3">{t("GO_TO_HOMEPAGE")}</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NotFound;